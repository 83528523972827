/* stylelint-disable unit-whitelist */
.backdrop {
  z-index: 1500 !important;
}

.imageContainer{
  width: 100px;
  height: 100px;
}

.logoImage{
  /* Image paths are now relative to what's set in tsconfig */
  background-image: url(flores-mark.svg);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  animation: pulseAnimation 2s linear infinite;
}

@keyframes pulseAnimation {
  0%   { opacity: 1; }
  50%  { opacity: 0.5; }
  100% { opacity: 1; }
}

.waitText{
  color: inherit;
}
/* stylelint-enable unit-whitelist */
