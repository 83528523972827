h1, h2, h3, h4, h5, h6 {
  color: var(--color-text-med-em);
  font-family: var(--font-family-main);
}

h1 {
  font-size: var(--h1-size);
  letter-spacing: var(--h1-letter-spacing);
  font-weight: var(--h1-weight);
}

h2 {
  font-size: var(--h2-size);
  letter-spacing: var(--h2-letter-spacing);
  font-weight: var(--h2-weight);
}

h3 {
  font-size: var(--h3-size);
  letter-spacing: var(--h3-letter-spacing);
  font-weight: var(--h3-weight);
}

h4 {
  font-size: var(--h4-size);
  letter-spacing: var(--h4-letter-spacing);
  font-weight: var(--h4-weight);
}

h5 {
  font-size: var(--h5-size);
  letter-spacing: var(--h5-letter-spacing);
  font-weight: var(--h5-weight);
}

h6 {
  font-size: var(--h6-size);
  letter-spacing: var(--h6-letter-spacing);
  font-weight: var(--h6-weight);
}

.page-title {
  @apply w-full mt-8 mb-12 text-2-2/3xl text-high-em;
}
