.eventInfoFormField {
    width: 100%;
  }

  .twoCol .eventInfoFormField:nth-child(odd) {
    width: 49.5%;
    margin-right: 0.5%;
  }

  .twoCol .eventInfoFormField:nth-child(even) {
    width: 49.5%;
    margin-left: 0.5%;
  }
