
.accountManagerTile .gradient {
  height: 140px;
  /* stylelint-disable unit-whitelist */
  background: linear-gradient(249deg, #3299CD 0%, #00558D 100%) 0% 0% no-repeat;
  /* stylelint-enable */
}

.tileFooter {
  border-top: 1px solid var(--color-neutral4);
}
