.tile {
  width: 15rem;
  cursor: pointer;
}

.media {
  width: 15rem;
  height: 8rem;
  background-color: white;
  background-position: bottom !important;
  background-size: auto !important;
}

.content {
  height: 6rem;
  text-align: center;
  padding: 1rem !important;
}
