.mainContainer {
  margin-top: var(--toolbar-height);
}

@media (min-width: 1024px) {
  .mainContainer {
    margin-left: var(--sidebar-width);
  }
}

@media (min-width: 1024px) {
  .header {
    width: calc(100% - var(--sidebar-width));
    margin-left: var(--sidebar-width);
  }
}

