.summaryContainer {
  @apply flex flex-col border border-solid border-gray-300 rounded-sm;
  min-height: 13rem;
}

.dataSection {
  @apply flex flex-col justify-center border-solid border-gray-300 text-center p-4;
  min-width: 11rem;
}

.color {
  color: var(--color-primary-dark);
}
