@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

/* stylelint-disable unit-whitelist */
:root {
  --font-family-main: "Roboto", Helvetica, Arial, sans-serif;
  --font-base-size: 15px;
  --font-base-weight: 400;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --h1-size: 6rem;
  --h1-letter-spacing: -1.5px;
  --h1-weight: 300;

  --h2-size: 3.75rem;
  --h2-letter-spacing: -0.5px;
  --h2-weight: 300;

  --h3-size: 3rem;
  --h3-letter-spacing: 0;
  --h3-weight: 400;

  --h4-size: 2.125rem;
  --h4-letter-spacing: 0.25px;
  --h4-weight: 400;

  --h5-size: 1.5rem;
  --h5-letter-spacing: 0;
  --h5-weight: 400;

  --h6-size: 1.25rem;
  --h6-letter-spacing: 0.25px;
  --h6-weight: 500;
}
/* stylelint-enable unit-whitelist */
