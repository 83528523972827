:root {
  --sidebar-width: 16.25rem;
  --toolbar-height: 3.5rem;
}

@media (min-width: 1024px) {
  :root {
    --toolbar-height: 4rem;
  }
}

#body .-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}
