:root {
  --color-primary-light: #5AAAF7;
  --color-primary-main: #007BC4;
  --color-primary-dark: #005093;

  --color-secondary-light: #FFD149;
  --color-secondary-main: #FFA000;
  --color-secondary-dark: #D68700;

  --color-surface: white;
  --color-surface-light: white;
  --color-surface-dark: #F3F3F3;

  --color-text-high-em: rgba(0,0,0, 0.87);
  --color-text-med-em: rgba(0,0,0, 0.6);
  --color-text-low-em: rgba(0,0,0, 0.38);

  /* These are from the Flores Style Guide and may not be applicable here */
  --color-brand-primary: #00558D;
  --color-brand-accent: #3299CD;
  --color-brand-complementary: #FF933B;
  --color-brand-dark: #2C3845;

  --color-neutral1: #4A4E4F;
  --color-neutral2: #7F7F7F;
  --color-neutral3: #BFBFBF;
  --color-neutral4: #EDEDEE;
  --color-neutral5: #E5E5E5;
  --color-neutral6: #F4F6F8;
  --color-geyser: #CFD8DC;
  --color-gray-chateu: #9AA0A6;
  --color-stone: #798084;
  --color-outer-space: #263238;

  --color-hsa-primary: #87509E;
  --color-hsa-accent: #595199;
  --color-hsa-secondary-accent: #3A5999;
  --color-hsa-secondary: #1A6FAA;

  --color-caution: #FFB74D;
  --color-danger: #F44336;
  --color-danger-text: #FFFFFF;
}
