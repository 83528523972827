button[class*='MuiButton-containedPrimary'] {
    background-color: var(--color-primary-main)!important;
}

button[class*='MuiButton-containedPrimary']:hover {
    background-color: var(--color-primary-dark) !important;
}

button[class*='MuiButton-containedPrimary']:disabled {
    background-color: var(--color-neutral3) !important;
}
